// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import '@metronic/_assets/sass/style.react.scss';

@media (min-width: 992px) {
  // .aside-fixed .aside {
  //   // padding-top: 80px !important;
  // }
}

body {
  background-color: #f6f4f8 !important;
}

.aside-secondary-enabled .aside {
  width: 250px !important;
}
.aside-menu > .menu-nav > .menu-logo {
  margin-bottom: 2em;
}
@media (min-width: 992px) {
  .aside-secondary-enabled.aside-fixed .wrapper {
    padding-left: 250px !important;
  }
}

.menu-link.active {
  background-color: transparent !important;
}

.aside {
  box-shadow: 5px 0px 15px rgb(246, 244, 248), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.aside,
.aside-content,
.tab-content,
.aside-menu {
  // background-color: #f2fbff !important;
  background-color: #fff !important;
}

// .aside,
// .aside-menu {
//   // display: none !important;
// }

.breadcrumb.breadcrumb-dot .breadcrumb-item:after {
  content: '\2022';
  padding-left: 0.5rem;
  height: auto;
  width: auto;
}

.aside .aside-secondary .aside-workspace {
  width: 265px !important;
  flex-shrink: 0;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-text {
  font-weight: bold;
}

.aside-menu .menu-nav > .menu-item.menu-item-active {
  background-color: #f4edff;
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.bold {
  font-weight: bold !important;
}
.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.react-bootstrap-table th {
  text-transform: uppercase;
  color: #b5b5c4;
  font-weight: 500;
}

.cursor-default {
  cursor: default !important;
}

.hover-cursor-pointer:hover {
  cursor: pointer;
}
// Custom
.form-control:focus {
  border-color: #e4e6ef;
}
.input-group-text {
  background-color: transparent;
}

.border-left-none {
  border-left: 0px;
}

.border-right-none {
  border-right: 0px;
}

// table caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.btn-secondary.dropdown-toggle .caret {
  display: none;
}

.react-bootstrap-table-page-btns-ul {
  margin-top: 12px;
}

a.page-link {
  border-radius: 0.42rem;
  line-height: 18px;
}

li[title='next page'].page-item .page-link,
li[title='last page'].page-item .page-link,
li[title='first page'].page-item .page-link,
li[title='previous page'].page-item .page-link {
  background-color: #f1e8fe;
  border-radius: 0.42rem;
}

.pagination,
.sizePerPage {
  justify-content: center !important;
}
@media (min-width: 992px) {
  .pagination {
    justify-content: flex-start !important;
  }

  .sizePerPage {
    justify-content: flex-end !important;
  }
}

// Seperator (vertical line) in select2 dropdown
.css-1okebmr-indicatorSeparator {
  display: none;
}

li.page-item {
  margin-left: 2px;
  margin-right: 2px;
}
a.page-link {
  background-color: transparent;
  border: none;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

// react-bootstrap-table custom style
.table-hover tbody tr:hover {
  cursor: pointer;
}

.react-bootstrap-table > table > thead {
  // border-top: 2px solid #80808030;
  border-bottom: 2px solid #80808030;
}
.pagination {
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .content {
    margin-top: 80px !important;
  }
}

.width-100 {
  width: 100%;
}

input:disabled,
textarea:disabled {
  color: #9093a4;
}

.custom-is-invalid {
  border: 1px solid #f64e60 !important;
  border-radius: 0.42rem;
}

.white-space-nowrap {
  white-space: nowrap;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

// Custom file input adjustable height
.custom-file-input ~ .custom-file-label[data-browse]::after,
.custom-file-label {
  display: flex;
  align-items: center;
  height: 100%;
  color: #3b0a84;
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  background-color: #f1e8fe;
}
// size="lg"
.custom-file-input,
.custom-file {
  height: calc(1.5em + 1.65rem + 2px) !important;
}

.custom-file-input:hover {
  cursor: pointer;
}
.custom-file-input:focus ~ .custom-file-label {
  border: 1px solid #e4e6ef !important;
}

.modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  line-height: 20px;
  color: #b5b5c4;
  font-weight: 100;
  z-index: 99;
}
.modal-close:hover {
  cursor: pointer;
}

.react-datepicker-wrapper {
  display: block !important;
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.ck-editor__main i {
  color: inherit;
  font-size: inherit;
}
